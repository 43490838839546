/* 최상단에 Google Fonts import */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');

/* 채팅 메시지 컨테이너 스타일 */
.chat-message {
  margin-bottom: 4rem;
  display: flex;
  align-items: flex-start; /* 상단 정렬로 변경 */
  position: relative;
  padding: 0px 0.625rem;
  
}

/* 사용자 메시지 정렬 (오른쪽) */
.chat-message-human {
  justify-content: flex-end;
  
}

/* AI 메시지 정렬 (왼쪽) */
.chat-message-ai { 
  justify-content: flex-start; 
  
} 

.avatar-container-human {
  order: 1;
  margin-left: 0.95rem; /* 아바타와 메시지 버블 사이의 간격 */
}

/* AI 아바타 컨테이너 스타일 */
.avatar-container-ai {
  order: -1;
  margin-right: 0.4rem; /* 아바타와 메시지 버블 사이의 간격 */
}


/* 메시지 버블 스타일 */
.message-bubble {
  max-width: 70%;
  padding: 0.625rem;
  border-radius: 0.9375rem;
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
  position: relative;
  display: inline-block;
  text-align: left;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  font-size: 0.9em;
  line-height: 1.3;
  overflow: visible;
  white-space: normal;
}

/* AI 메시지 버블 스타일 수정 */
.message-bubble-ai { 
  background-color: var(--message-box); 
  color: var(--text-color); 
  padding-bottom: 1.25rem;
}

/* 사용자 메시지 버블 스타일 수정 */
.message-bubble-human { 
  background-color: var(--message-box); 
  color: var(--text-color); 
  padding-bottom: 1.25rem;
}

/* 스크롤바 스타일 (선택사항) */
.message-bubble::-webkit-scrollbar {
  width: 0.375rem;
}

.message-bubble::-webkit-scrollbar-track {
  background: transparent;
}

.message-bubble::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.1875rem;
}

/* 메시지 내용 스타일 */
.message-content {
  white-space: pre-wrap; /* 줄바꿈 유지 */
  word-break: break-word; /* 긴 단어도 줄바꿈 */
}


/* 타임스탬프 공통 스타일 */
.timestamp-container { 
  font-size: 0.7em;
  opacity: 0.7;
  white-space: nowrap; 
}

/* Human 타임스탬프 스타일 */
.chat-message-human .timestamp-container {
  order: -1; /* 타임스탬프를 메시지 버블 앞으로 */
  margin-right: 0.625rem;
  color: var(--timestamp);
  background-color: var(--message-human-background);
  padding:0.125rem 0.3125rem;
  border-radius: 0.3125rem;
  align-self: flex-end;
  
}

/* AI 타임스탬프 스타일 */
.chat-message-ai .timestamp-container { 
  margin-left: 0.625rem; /* 버블 메시지와의 간격 */
  color: var(--timestamp); 
  background-color: var(--message-ai-background); /* ai bubble message와 동일한 색상 */
  padding: 0.125rem 0.3125rem;
  border-radius: 0.3125rem;
  align-self: flex-end; /* 오른쪽 아래 위치 */
} 

/* 메시지 진입 애니메이션 (시작 상태) */
.message-enter { 
  transform: scale(0.9); 
  opacity: 0.5; 
} 

/* 메시지 진입 애니메이션 (활성 상태) */
.message-enter-active { 
  transform: scale(1); 
  opacity: 1; 
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; 
} 

/* 메시지 퇴장 애니메이션 (시작 상태) */
.message-exit { 
  transform: scale(1); 
  opacity: 1; 
} 

/* 메시지 퇴장 애니메이션 (활성 상태) */
.message-exit-active { 
  transform: scale(0.9); 
  opacity: 0.5; 
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; 
} 

/* 메시지 내 이모지 스타일 */
.message-bubble .emoji { 
  margin-right: 0.625rem;
} 

/* 로딩 표시 스타일 */
.loading { 
  display: flex; 
  align-items: center; 
  justify-content: center; 
  font-size: 1.2em; /* 기본 폰트 크기 */
  color: var(--text-color); 
}

.input-form {
  display: flex;
  flex-direction: row; /* 가로 정렬로 변경 */
  align-items: center; /* 세로 중앙 정렬 */
  gap: 0.625rem;
  width: 100%;
  max-width: 50rem;
  position: fixed;
  bottom: 3.6rem  !important; /* 값을 변경하고 !important 추가 */
  left: 50%;
  transform: translateX(-50%);
  background: var(--input-background);
  padding: 0.625rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem; 
  z-index: 1000;
}

/* 메시지 영역 스타일 */
.messages {
  flex-grow: 1;
  max-width: 95%; 
  width: 95%; 
  background-color: var(--message-background);
  padding: 1.25rem 1.5rem;
  border-radius: 0.75rem;
  overflow-y: auto;
  overflow-x: hidden; /* 가로 스크롤 방지 */
  margin: 0.625rem auto; /* 상하 마진은 유지하고 좌우는 auto로 설정 */
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1), 0 0 0 0.5rem #808080;
  max-height: calc(100vh - 8.5rem); /* 상단 네비게이션과 하단 입력 폼을 고려한 높이 */
  height: calc(100vh - 8.5rem);
  padding-bottom: 3.75rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border: 0.0625rem solid rgba(128, 128, 128, 0.3);
  position: relative;
}

/* 메시지 입력 텍스트 영역 스타일 */
textarea[name="message"] {
  flex-grow: 1;
  padding: 0.3125rem 0.625rem;
  border: 1px solid var(--input-border);
  border-radius: 0.25rem;
  background-color: var(--input-background);
  color: var(--text-color);
  resize: none;
  height: 2.5rem;
  min-height: 2.5rem;
  overflow-y: hidden;
  line-height: 1.875rem;
}

/* 메시지 입력 텍스트 영역 플레이스홀더 스타일 */
textarea[name="message"]::placeholder { 
  color: var(--placeholder-color); 
} 

/* 버튼 컨테이너 스타일 */
.button-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}


.mic-button {
  padding: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: transparent !important;
  color: transparent;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  background-image: url('../images/mic.png');
  background-size: 2rem;
  background-position: center;
  background-repeat: no-repeat;
}

/* 전송 버튼 스타일 */
.send-button {
  padding: 0;
  border: none;
  border-radius: 0.25rem;
  background-color: transparent !important;
  color: transparent;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  background-image: url('../images/sending_button.png');
  background-size: 2rem;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-image 0.3s ease;
}

.mic-button::after,
.send-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1.5rem; /* 이미지 크기 조정 */
  height: 1.5rem; /* 이미지 크기 조정 */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}



.mic-button:hover {
  background-image: url('../images/mic_hover.png');
}



/* 마이크 버튼 활성화 상태 스타일 */
.mic-button.mic-on { 
  background-image: url('../images/mic_stop2.png');
}



.mic-button.mic-on:hover{
  background-image: url('../images/mic_stop.png');
}



.send-button:hover {
  background-image: url('../images/sending_hover.png');
}

/* isResponding 또는 isAudioPlaying이 true일 때 sending_stop.png 표시 */
.send-button:active,
.input-form:has(textarea:disabled) .send-button {
  background-image: url('../images/sending_stop.png');
}

.input-form:has(textarea:disabled) .send-button:hover {
  background-image: url('../images/sending_stop_hover.png');
}

/* 버튼이 비활성화되었을 때 */
.send-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-image: url('../images/sending_button.png');
}

/* 아바타 이미지 스타일 */
.avatar { 
  width: 2.5rem;
  height: 2.5rem; 
  border-radius: 50%; 
}

/* 토글 스위치 스타일 */
.toggle-switch {
  width: 3.125rem; /* 50px를 3.125rem으로 변경 */
  height: 1.5rem;
  background-color: var(--toggle-background);
  border-radius: 0.75rem;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* 토글 노브 스타일 */
.toggle-switch .toggle-knob {
  width: 1.25rem; /* 20px를 1.25rem으로 변경 */
  height: 1.25rem;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 0.125rem; /* 2px를 0.125rem으로 변경 */
  left: 0.125rem;
  transition: left 0.3s ease;
}

/* 활성화된 토글 노브 스타일 */
.toggle-switch .toggle-knob.active {
  left: 1.75rem;
}

/* 토글 스위치 호버 효과 */
.toggle-switch:hover {
  opacity: 0.8;
}

/* 라이트 모드 변수 */
:root {
  --autoplay-toggle-background: #f0f0f0;
  --autoplay-toggle-text: #333;
  --toggle-background: #ccc;
  --toggle-active-background: #87CEEB; /* 하늘색으로 변경 */
  --message-human-background: #e6f3ff;
  --message-ai-background: #f0f0f0;
  --text-color: #333;
  --input-background: #fafafa;
  --input-border: #ccc;
  --placeholder-color: #999;
  --button-background: #e6b28a;
  --button-text: #ffffff;
  --button-hover: #ffffff;
  --button-active-background: #c67a79;
  --button-active-text: #ffffff;
  --timestamp: #060606;
}

/* 다크 모드 변수 */
@media (prefers-color-scheme: dark) 
  :root {
    --autoplay-toggle-background: #1a1a1a; /* 밝은 검은색 계열로 변경 */
    --autoplay-toggle-text: #e0e0e0;
    --toggle-background: #555;
    --toggle-active-background: #4a4a4a; /* 회색으로 변경 */
    --message-human-background: #2c3e50;
    --message-ai-background: #34495e;
    --text-color: #ecf0f1;
    --input-background: #2c3e50;
    --input-border: #34495e;
    --placeholder-color: #bdc3c7;
    --button-background: #2ecc71;
    --button-text: #ffffff;
    --button-hover: #27ae60;
    --button-active-background: #27ae60;
    --button-active-text: #ffffff;
    --timestamp: #black;
  }


/* 컨테이너에 상대 위치 지정 */
.container {
  position: relative;
}

.error-message {
  color: red;
  font-weight: bold;
}

/* Google Fonts에서 폰트 가져오기 */
@import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap');

/* chatbot.css */
.nanum-brush-script-regular {
  font-family: "Nanum Brush Script", cursive;
  font-weight: 400;
  font-style: normal;
}

.content h1 {
  /* .nanum-brush-script-regular 클래스를 h1에 적용 */
  font-family: "East Sea Dokdo", cursive;
  font-weight: 400;
  font-style: normal;
}

/* chatbot-title 클래스 스타일 수정 */
.chatbot-title {
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 700; /* bold로 변경 */
  font-size: 1.5rem;
  letter-spacing: -0.02em; /* 자간 살짝 조정 */
}


@media screen and (max-width: 48em) { /* 768px / 16px = 48em */
  .content {
    padding-top: 4rem; /* 상단 여백 증가 */
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .messages {
    max-width: 100%;
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem auto;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 18rem); /* 상단 헤더와 하단 입력 폼을 고려한 높이 조정 */
    height: auto; /* 고정 높이 제거 */
  }

  .chat-message {
    margin-bottom: 1rem;
  }

  .message-bubble {
    max-width: 80%;
    font-size: 0.85em;
    padding: 0.5rem;
  }

  .input-form {
    max-width: 95%;
    width: 95%;
    position: fixed;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    border-radius: 0.625rem;
    z-index: 1001; /* 더 높은 z-index 값 */
  }

  textarea[name="message"] {
    height: 2.5rem;
    min-height: 2.5rem;
    line-height: 1.5rem;
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 1.25rem;
  }

  .mic-button,
  .send-button {
    height: 2.5rem;
    width: 2.5rem;
    background-size: 1.75rem;
  }

  .avatar {
    width: 2rem;
    height: 2rem;
  }

  .timestamp-container {
    font-size: 0.6em;
  }

  /* 헤더 관련 스타일 */
  .header-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0.5rem;
    background-color: var(--background-color);
    z-index: 1000;
  }

  .chatbot-title {
    font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
  }

  .header-left,
  .header-center,
  .header-right {
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}