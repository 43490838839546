/* 사용자 정의 스타일 시작 */


body, html {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

/* 사용자 정의 네비게이션 바 스타일 */
.custom-navbar {
  background-color: transparent !important;
  padding: 0.625rem 1.25rem;
  box-shadow: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.custom-navbar:hover {
  background-color: var(--navbar-background) !important;
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
}

.brand-left {
  font-weight: bold;
  color: var(--brandcolor) !important;
}

.custom-navbar .nav-link:hover { 
  background-color: var(--navbar-hover-background);
}

/* 테마 전환 버튼 스타일 */
.theme-toggle-button {
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  color: var(--text-color);
  transition: color 0.3s ease;
}

.theme-toggle-button img {
  width: 4.375rem;
  height: 3.125rem;
  border-radius: 30%;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.content h1 {
  font-size: 4em;
  margin-top: 0;
  margin-bottom: 0.625rem;
}

.emoji {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.625rem;
}

.messages::-webkit-scrollbar {
  width: 0.75rem;
}

.messages::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 0.625rem;
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.messages::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 0.625rem;
  border: 0.1875rem solid var(--scrollbar-track);
}

.messages::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover);
}

.message-bubble {
  max-width: 60%;
  padding: 0.625rem;
  border-radius: 0.9375rem;
  box-shadow: 0 0.125rem 0.3125rem rgba(0, 0, 0, 0.1);
  position: relative;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  text-align: left;
}

/* 메시지 애니메이션 스타일 */
.message-enter {
  transform: scale(0.9);
  opacity: 0.5;
}

.message-enter-active {
  transform: scale(1);
  opacity: 1;
}

.message-exit {
  transform: scale(1);
  opacity: 1;
}

.message-exit-active {
  transform: scale(0.9);
  opacity: 0.5;
}

.message-bubble.human {
  background-color: var(--message-human-background);
  color: var(--message-box);
}

.message-bubble.ai {
  background-color: var(--message-ai-background);
  color: var(--message-box);
}

.message-bubble .emoji {
  margin-right: 0.625rem;
}

/* 로딩 표시 스타일 */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  color: white;
}

.input-form {
  display: flex;
  gap: 0.625rem;
  width: 100%;
  max-width: 50rem;
  position: fixed;
  bottom: 3.75rem;
  left: 50%;
  transform: translateX(-50%);
  background: var(--input-form-color);
  padding: 0.625rem;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
  border-radius: 0.625rem;
  z-index: 1000;
}

input[name="message"] {
  flex-grow: 1;
  padding: 0.625rem;
  border: 0.0625rem solid var(--input-border);
  border-radius: 0.25rem;
  background-color: var(--input-background);
  color: var(--text-color);
}

input[name="message"]::placeholder {
  color: var(--placeholder-color);
}

button[type="submit"] {
  padding: 0.625rem 1.25rem;
  border: none;
  border-radius: 0.25rem;
  color: var(--button-text);
  cursor: pointer;
}

button[type="submit"]:hover {
    background-color:transparent;
}

.avatar {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin-right: 0.625rem;
}


/* 라이트 테마 변수 */
:root {
    --background-color: #dad6d3; /* 전체 배경색을 연한 파란색으로 설정해요 */
    --text-color: #333333; /* 텍스트 색상을 어두운 회색으로 설정해요 */
    --navbar-background: #ab926c; 
    --navbar-text-color: #333333; 
    --navbar-hover-background: #e0aca6; 
    --message-background: #0a0807; /* 메시지 영역의 배경색을 매우 연한 파란색으로 설정해요 */
    --message-human-background: #f8f6f5; 
    --message-ai-background: #f8f6f5; 
    --scrollbar-track: transparent;
    --scrollbar-thumb: #5c5751; 
    --scrollbar-thumb-hover: #50443a; 
    --input-background: #fefdfd; /* 입력 필드의 배경색을 연한 파란색으로 설정해요 */
    --input-border: #e0aca6; /* 입력 필드의 테두리 색상을 파란색으로 설정해요 */
    --button-background: #a1726e; /* 버튼의 배경색을 어두운 파란색으로 설정해요 */
    --button-text: #FFFFFF; /* 버튼의 텍스트 색상을 흰색으로 설정해요 */
    --button-hover: #c19b8b; 
    --placeholder-color: #050505; 
    --timestamp-background: #ffe6c9; 
    --brandcolor: #07090a;
    --bordercolor: rgb(239, 239, 239);
    --input-form-color: #484644;
    --message-box: rgb(255, 255, 255);
}


/* 다크 테마 변수 */
body.dark-mode {
    --background-color: #1A1A1A; /* 전체 배경색을 어두운 회색으로 설정해요 */
    --text-color: #F0F0F0; /* 텍스트 색상을 연한 회색으로 설정해요 */
    --navbar-background: #2A2A2A; /* 네비게이션 바의 배경색을 더 어두운 회색으로 설정해요 */
    --navbar-text-color: #F0F0F0; /* 네비게이션 바의 텍스트 색상을 연한 회색으로 설정해요 */
    --navbar-hover-background: #3A3A3A; /* 네비게이션 바의 마우스 오버 배경색을 더 어두운 회색으로 설정해요 */
    --message-background: #2A2A2A; /* 메시지 영역의 배경색을 어두운 회색으로 설정해요 */
    --message-human-background: #f8f6f5; /* 사람 메시지의 배경색을 파란색으로 설정해요 */
    --message-ai-background: #f8f6f5; /* AI 메시지의 배경색을 연한 파란색으로 설정해요 */
    --scrollbar-track: #3A3A3A; 
    --scrollbar-thumb: #4b4c4e; 
    --scrollbar-thumb-hover: #333131; 
    --input-background: #2A2A2A; /* 입력 필드의 배경색을 어두운 회색으로 설정해요 */
    --input-border: #4A4A4A; /* 입력 필드의 테두리 색상을 중간 회색으로 설정해요 */
    --button-background: #ffffff; 
    --button-text: #070606; 
    --button-hover: #eecccc; 
    --placeholder-color: #C0C0C0; /* 플레이스홀더 텍스트의 색상을 연한 회색으로 설정해요 */
    --timestamp-background: #3A3A3A; /* 타임스탬프의 배경색을 어두운 회색으로 설정해요 */
    --brandcolor: #fafafa;
    --bordercolor: black;
    --input-form-color: black;
    --message-box: rgb(88, 87, 87);
}


.brand-container {
    display: flex;
    align-items: center;
  }
  
  .logo-image {
    height: 30px;
    width: auto;
    margin-right: 10px;
  }
  
  .theme-icon {
    width: 64px !important;
    height: 40px !important;
  }
  
  /* 기본 스타일 */
.loading {
    font-size: 16px; /* 기본 텍스트 크기 */
    text-align: center;
  }
  
/* 토글 스위치 스타일 */
.switch {
  position: relative;
  display: inline-block;
  width: 6rem;
  height: 3rem;
  margin-left: auto;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to right, #f0f0f0, #e0e0e0);
  transition: .4s;
  border: 0.15rem solid #b7aea7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 0.75rem;
  font-weight: bold;
}

.slider:before {
  position: absolute;
  content: "";
  height: 2.4rem;
  width: 2.4rem;
  left: 0.15rem;
  bottom: 0.15rem;
  background-color: white;
  transition: .4s;
  background-image: url('./images/litemode.png');
  background-size: cover;
  background-position: center;
}

.slider::after {
  content: "LIGHT\aMODE";
  white-space: pre;
  position: absolute;
  right: 0.6rem;
  color: #333;
  text-align: left;
  line-height: 1.2;
}

input:checked + .slider {
  background: linear-gradient(to right, #333333, #1a1a1a);
  border: 0.15rem solid white;
  color: white;
}

input:checked + .slider:before {
  transform: translateX(3rem);
  background-color: white;
  background-image: url('./images/darkmode.png');
}

input:checked + .slider::after {
  content: "DARK\aMODE";
  left: 0.6rem;
  right: auto;
  color: white;
}

.slider.round {
  border-radius: 2.55rem;
}

.slider.round:before {
  border-radius: 50%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-center {
  flex: 2;
  display: flex;
  justify-content: center;
}
  
.navbar-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.chatbot-title {
  font-family: "East Sea Dokdo", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 2rem;
  color: var(--brandcolor);
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 1.25rem;
  background-color: var(--background-color);
}

.header-left, .header-right {
  flex: 1;
}
  
.header-center {
  flex: 2;
  text-align: center;
}

.header-right {
  display: flex;
  justify-content: flex-end;
}

.footer {
  position: fixed;
  bottom: 0.625rem;
  right: 1.25rem;
  z-index: 1000;
}

.footer-text {
  font-size: 0.875rem;
  color: var(--text-color);
  opacity: 0.7;
}

/* App.css 파일 끝에 추가 */
@media screen and (max-width: 48em) { /* 768px / 16px = 48em */
  body, html {
    font-size: 0.875rem; /* 14px */
  }

  .App {
    padding: 0;
  }

  .custom-navbar {
    padding: 0.5rem;
  }

  .brand-left {
    font-size: 1.25rem;
  }

  .chatbot-title {
    font-size: 1.5rem;
  }

  .content h1 {
    font-size: 2.5em;
  }

  .switch {
    width: 5rem; /* 4rem에서 5rem으로 증가 */
    height: 2rem;
  }

  .slider:before {
    height: 1.6rem;
    width: 1.6rem;
  }

  .slider::after {
    font-size: 0.6rem;
    right: 0.4rem;
  }

  input:checked + .slider:before {
    transform: translateX(3rem); /* 2rem에서 3rem으로 증가 */
  }

  input:checked + .slider::after {
    left: 0.4rem;
  }

  .footer {
    bottom: 0.5rem;
    right: 0.5rem;
  }

  .footer-text {
    font-size: 0.75rem;
  }

  .navbar-container {
    flex-direction: column;
    align-items: center;
  }

  .navbar-left,
  .navbar-center,
  .navbar-right {
    width: 100%;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
}